import { createSlice } from '@reduxjs/toolkit'
import {
  fireDomesticQuotation,
  getCell,
  getDistrict,
  getProvince,
  getSector,
  getUpdatedPremiumCalculation,
  getVillages,
  kycSubmitionAction,
  makePayment,
  submitMotoQuotationAction,
} from 'src/actions/quotation/quotation-action'
const buyStateObj = {
  isRiskFormValid: false,
  step: 0,
  isCalculator: false,
  nida: false,
  payment: {},
  master: {},
  productCode: '',
  riskInfo: {},
  fleet: false,
  coverPeriod: {},
  coverInfo: {},
  policeHolder: {},
  calculatorResults: {},
  disableRiskDate: false,
  quotationUpdates: {},
  premium: {},
  allRisks: [],
  provinces: {},
  districts: {},
  sectors: {},
  cells: {},
  villages: {},
  loading: false,
  error: false,
  message: false,
  success: false,
  triggerPH: false,
}

const BuyNewInsuranceInfo = createSlice({
  name: 'createNewPolice',
  initialState: buyStateObj,
  reducers: {
    updateNidaState: (state, action) => {
      return { ...state, nida: action?.payload }
    },
    webSocketPaymentStatusUpdate: (state, action) => {
      return { ...state, quotationUpdates: action?.payload }
    },
    clearAndAddNewItemRisk: (state) => {
      return {
        ...state,
        master: state.allRisks.length === 1 ? state.coverPeriod : state.master,
        isRiskFormValid: false,
        step: 0,
        payment: {},
        clientId: 0,
        fleet: true,
        disableRiskDate: true,
        riskInfo: {},
        coverPeriod: {},
        coverInfo: {},
        policeHolder: {},
        calculatorResults: {},
        loading: false,
        error: false,
        message: false,
        success: false,
        triggerPH: false,
      }
    },
    showCalculator: (state, action) => {
      return { ...state, isCalculator: action?.payload }
    },
    validateRiskForm: (state, action) => {
      return { ...state, isRiskFormValid: action?.payload }
    },
    addNewRiskToItems: (state, action) => {
      return { ...state, allRisks: action.payload }
    },
    enableNextEvent: (state, action) => {
      return { ...state, triggerPH: action?.payload }
    },
    changeStep: (state, action) => {
      return { ...state, step: action.payload }
    },
    storeProduct: (state, action) => {
      return { ...state, productCode: action.payload }
    },
    persistRiskInfo: (state, action) => {
      return { ...state, riskInfo: action.payload }
    },
    persistCoverInfo: (state, action) => {
      return { ...state, coverInfo: action.payload }
    },
    persistPolicyHolderInfo: (state, action) => {
      return { ...state, policeHolder: action?.payload }
    },
    perisistCoverPeriod: (state, action) => {
      return { ...state, coverPeriod: action.payload }
    },
    paymentPersist: (state, action) => {
      return { ...state, payment: action.payload }
    },
  },
  extraReducers: (bP) => {
    bP.addCase(kycSubmitionAction.pending, (state) => {
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      }
    })
      .addCase(kycSubmitionAction.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          clientId: action?.payload?.data?.cisReferenceId,
          message: action?.payload?.message,
        }
      })
      .addCase(kycSubmitionAction.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(submitMotoQuotationAction.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(submitMotoQuotationAction.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          premium: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(submitMotoQuotationAction.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(fireDomesticQuotation.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(fireDomesticQuotation.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          premium: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(fireDomesticQuotation.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(getProvince.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getProvince.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          provinces: action.payload?.data || [],
          message: action.payload.message,
        }
      })
      .addCase(getProvince.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(getDistrict.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getDistrict.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          districts: action.payload?.data || [],
          message: action.payload.message,
        }
      })
      .addCase(getDistrict.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(getSector.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getSector.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          sectors: action.payload?.data || [],
          message: action.payload.message,
        }
      })
      .addCase(getSector.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(getCell.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getCell.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          cells: action.payload?.data || [],
          message: action.payload.message,
        }
      })
      .addCase(getCell.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(getVillages.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getVillages.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          villages: action.payload?.data || [],
          message: action.payload.message,
        }
      })
      .addCase(getVillages.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(getUpdatedPremiumCalculation.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getUpdatedPremiumCalculation.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          calculatorResults: action.payload?.data,
          message: action.payload.message,
        }
      })
      .addCase(getUpdatedPremiumCalculation.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(makePayment.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(makePayment.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          message: action.payload.message,
        }
      })
      .addCase(makePayment.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
  },
})
export const {
  storeProduct,
  changeStep,
  validateRiskForm,
  enableNextEvent,
  persistRiskInfo,
  persistPolicyHolderInfo,
  paymentPersist,
  addNewRiskToItems,
  persistCoverInfo,
  webSocketPaymentStatusUpdate,
  updateNidaState,
  perisistCoverPeriod,
  showCalculator,
  clearAndAddNewItemRisk,
} = BuyNewInsuranceInfo.actions
export default BuyNewInsuranceInfo.reducer

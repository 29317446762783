import { hexToRgba } from '@coreui/utils'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import { json } from 'react-router-dom'
import apiCall, { apiCall2 } from 'src/backend/http'
export const createNewBeneficialOwnerAction = createAsyncThunk(
  'insurance/uob/addNewUob',
  async (requestData, toolkit) => {
    try {
      const { data } = await apiCall.post('insurance/uob/addNewUob', requestData)
      return data
    } catch (error) {
      return toolkit.rejectWithValue(error.response.data)
    }
  },
)

export const GetBeneficialOwnerReport = createAsyncThunk(
  'insurance/uob/beneficial-companies',
  async (req, tool) => {
    try {
      const { to, from } = req
      const { data } = await apiCall.get(
        `insurance/uob/beneficial-companies?startDate=${from}&endDate=${to}`,
      )
      return data
    } catch (error) {
      return tool.rejectWithValue(error.response.data)
    }
  },
)
export const GetQuotationReport = createAsyncThunk(
  'insurance/bk-tech/quotation/report',
  async (req, tool) => {
    try {
      const { to, from } = req
      const { data } = await apiCall.get(
        `insurance/bk-tech/quotation/report?startDate=${from}&endDate=${to}`,
      )
      return data
    } catch (error) {
      return tool.rejectWithValue(error.response.data)
    }
  },
)
export const repushEbmThatHasError = createAsyncThunk(
  'ebm-bridge/ebm/repush',
  async (req, tool) => {
    try {
      const { data, status } = await apiCall2.post(`ebm-bridge/ebm/repush`, req)
      if (status == 200) {
        NotificationManager.success(data?.message)
      } else {
        NotificationManager.warning(`Warning/Error Found:${data?.message}`)
      }
      return data
    } catch (error) {
      return tool.rejectWithValue(error.response.data)
    }
  },
)
export const getAllEbmData = createAsyncThunk('ebm-bridge/ebm/invoices', async (req, tool) => {
  try {
    const { to, from } = req
    const { data } = await apiCall2.get(`ebm-bridge/ebm/invoices?from=${from}&to=${to}`)
    return data
  } catch (error) {
    return tool.rejectWithValue(error.response.data)
  }
})
export const GetNidaUserInfo = createAsyncThunk(
  'nida/customer-info',
  async (nationalId, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.get(`nida/customer-info?documentNo=${nationalId}`)

      return data
    } catch (e) {
      console.error('API Error', e.response.data)
      return rejectWithValue(e.response.data)
    }
  },
)

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationManager } from 'react-notifications'
import apiCall from 'src/backend/http'
import { formatDate } from 'src/backend/utility'
import { addNewRiskToItems, changeStep } from 'src/slices/buyInsuranceSlice'
export const kycSubmitionAction = createAsyncThunk(
  'insurance/bk-tech/onBoardCustomer',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/onBoardCustomer', req)
      if (status === 200) {
        const {
          app: {
            newPolicy: { step },
          },
        } = getState()
        NotificationManager.success('KYC information has been submitted successfully!')
        dispatch(changeStep(step + 1))
        return data
      } else {
        NotificationManager.error('Failed to submit form')
        return rejectWithValue('Failed to submit form')
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message // Get the specific error message from the server
          : error.message // Fallback to the generic error message
      console.log(errorMessage)
      NotificationManager.error(errorMessage) // Display the specific error message
      return rejectWithValue(errorMessage)
    }
  },
)
export const getUpdatedPremiumCalculation = createAsyncThunk(
  'insurance/bk-tech/moto/premium/calculator',
  async (__, { rejectWithValue, dispatch, getState }) => {
    try {
      const {
        app: {
          newPolicy: { riskInfo, productCode, coverInfo, allRisks, step },
        },
      } = getState()
      let postReq = calcPostData(riskInfo, productCode, coverInfo)
      const { data, status } = await apiCall.post(
        'insurance/bk-tech/moto/premium/calculator',
        postReq,
      )
      if (status == 200) {
        await dispatch(addNewRiskToItems(updateRiskList(riskInfo, coverInfo, allRisks, data)))
        if (step > 0) {
          dispatch(changeStep(step - 1))
        }
      }
      return data
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  },
)

export const updateRiskList = (riskInfo, coverInfo, allRisks, calculatorResults) => {
  const newRisk = { ...calculatorResults, ...riskInfo, ...coverInfo }
  const existingRiskIndex = allRisks.findIndex(
    (risk) => risk?.data?.riskId === newRisk?.data?.riskId,
  )

  if (existingRiskIndex !== -1) {
    // Replace the existing risk
    return allRisks.map((risk, index) => (index === existingRiskIndex ? newRisk : risk))
  } else {
    // Create a new array with the new risk added
    return [...allRisks, newRisk]
  }
}

export const fireDomesticQuotation = createAsyncThunk(
  'insurance/fire/residential',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/fire/residential', req)

      if (status == 200) {
        NotificationManager.success(data?.message) // Show success notification
        const {
          app: {
            newPolicy: { step },
            ui_display: {},
          },
        } = getState()

        dispatch(changeStep(step + 1))
        // Dispatch change step action on success
      } else {
        NotificationManager.warning(`Warning/Error Found: ${data?.message}`) // Show warning for non-200 status
      }
      return data
    } catch (ex) {
      NotificationManager.error(`Error: ${ex.response.data?.message}`) // Changed to error notification

      return rejectWithValue(ex.response.data)
    }
  },
)
export const makePayment = createAsyncThunk(
  'insurance/bk-tech/payQuotation',
  async (request, { rejectWithValue, dispatch, getState }) => {
    const {
      app: {
        newPolicy: { step },
      },
    } = getState()
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/payQuotation', request)
      if (status == 200) {
        NotificationManager.success(data?.message)
      } else if (status === 202) {
        NotificationManager.info(`Notifing Client: ${data?.message}`)
        dispatch(changeStep(step + 1))
      } else {
        NotificationManager.error(`Error: ${data?.message}`)
      }
      return data
    } catch (error) {
      NotificationManager.error(`Error: ${error.response.data?.message}`) // Changed to error notification
      return rejectWithValue(error.response.data)
    }
  },
)
const submitMotoQuotationAction = createAsyncThunk(
  'insurance/moto/calculate-premium',
  async (requestData, { rejectWithValue, dispatch, getState }) => {
    try {
      const url = 'insurance/bk-tech/motor/private/quotation'
      const { data, status } = await apiCall.post(url, requestData)
      if (status == 200) {
        NotificationManager.success(data?.message)
        const {
          app: {
            newPolicy: { step },
          },
        } = getState()
        dispatch(changeStep(step + 1))
      } else {
        NotificationManager.warning(`Warning/Error Found: ${data?.message}`) // Show warning for non-200 status
      }
      return data
    } catch (error) {
      NotificationManager.error(`Error: ${error.response.data?.message}`) // Changed to error notification
      return rejectWithValue(error.response.data) // Reject with error data
    }
  },
)
/*
const submitMotoQuotationAction = createAsyncThunk(
  'insurance/moto/calculate-premium',
  async (requestData, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/moto/quotation', requestData)
      console.log(JSON.stringify(data))
      if (status == 200) {
        NotificationManager.success(data?.message)
        const {
          app: {
            newPolicy: { step },
          },
        } = getState()
        console.log(step)
        dispatch(changeStep(step + 1))
        NotificationManager.warning(`Warning/Error Found:${data?.message}`)
      } else {
        NotificationManager.warning(`Warning/Error Found:${data?.message}`)
      }

      return data
    } catch (error) {
      console.log(JSON.stringify(error.response.data))
      NotificationManager.warning(`Warning/Error Found:${error.response.data?.message}`)
      return rejectWithValue(error.response.data)
    }
  },
)*/
const submitFireQuotation = createAsyncThunk(
  'insurance/bk-tech/fire/quotation',
  async (postData, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/fire/quotation', postData)
      if (status != 200) {
        NotificationManager.error(data.message)
        return rejectWithValue(data.message)
      } else {
        const {
          app: {
            newPolicy: { step },
          },
        } = getState()
      }
      return data
    } catch (error) {
      return rejectWithValue(error.response.data || error.message)
    }
  },
)
const sendQuotationToEmail = createAsyncThunk(
  'notification/email/send-quotation',
  async (postReq, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.post('notification/email/send-quotation', postReq)
      return data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  },
)
const submitNoMotoQuotation = createAsyncThunk(
  'insurance/non-moto/calculate-premium',
  async (postData, toolKit) => {
    try {
      const {
        app: {
          bkProduct: { selectProduct },
        },
      } = toolKit.getState()
      let url =
        selectProduct.product_CODE === 'TRAVEL_INS'
          ? 'insurance/bk-tech/travel/quotation'
          : 'insurance/non-moto/calculate-premium'

      const { data } = await apiCall.post(url, postData)
      return data
    } catch (error) {
      return toolKit.rejectWithValue(error.response.data)
    }
  },
)
const submitPersonalAccidentQuotation = createAsyncThunk(
  'insurance/bk-tech/pa/quotation',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.post('/insurance/bk-tech/pa/quotation', postData)
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
const getNidaInformation = createAsyncThunk(
  'nida/customer-info',
  async (nationalId, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.get(`nida/customer-info?documentNo=${nationalId}`)
      return data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  },
)

const getProvince = createAsyncThunk('insurance/location/provinces', async (rejectWithValue) => {
  try {
    const response = await apiCall.get('insurance/location/provinces')
    return response
  } catch (error) {
    return rejectWithValue(error.response.response)
  }
})

const getDistrict = createAsyncThunk(
  'insurance/location/districts',
  async (provinceCode, { rejectWithValue }) => {
    try {
      const response = await apiCall.get(
        `insurance/location/districts?provinceCode=${provinceCode}`,
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

const getSector = createAsyncThunk(
  'insurance/location/sectors?districtCode',
  async (districtCode, { rejectWithValue }) => {
    try {
      const response = await apiCall.get(`insurance/location/sectors?districtCode=${districtCode}`)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.response)
    }
  },
)
const getCell = createAsyncThunk(
  'insurance/location/cells?sectorCode',
  async (sectorCode, { rejectWithValue }) => {
    try {
      const response = await apiCall.get(`insurance/location/cells?sectorCode=${sectorCode}`)

      return response.data
    } catch (error) {
      return rejectWithValue(error.response.response)
    }
  },
)
const getVillages = createAsyncThunk(
  'insurance/location/villages?cellCode',
  async (cellCode, { rejectWithValue }) => {
    try {
      const response = await apiCall.get(`insurance/location/villages?cellCode=${cellCode}`)
      return response
    } catch (error) {
      return rejectWithValue(error.response.response)
    }
  },
)
const calcPostData = (riskInfo, productCode, coverInfo) => {
  return {
    policeDetails: {
      startDate: formatDate(coverInfo?.startDate),
      expiryDate: formatDate(coverInfo?.endDate),
      comesaStartDate: formatDate(coverInfo?.comesaStartDate),
      comesaEndDate: formatDate(coverInfo?.comesaEndDate),
      productCode: productCode,
      comesa: coverInfo?.comesa,
      covers: coverInfo?.covers,
      comesaCovers: coverInfo?.comesaCovers,
      cmedical: false,
      clientType: 'PRIVATE',
      sumInsuredPerOccupants: coverInfo?.sumInsuredPerOccupants,
      numberOfOccupants: coverInfo?.numberOfOccupants,
    },
    vehicleDetails: {
      plateNo: riskInfo?.plateNo,
      manufacturingYear: riskInfo?.yearOfMake,
      vehicleTypeId: riskInfo?.motoTypeItem?.motoTypeId,
      noOfSeats: riskInfo?.motoTypeItem?.seats,
      valueOfVehicle: 0,
    },
    source: 'WEB_PORTAL',
  }
}
const restSubmitionStatus = createAction('RESET_QUOTATION_SUBMISSION')
const showHideFirePreview = createAction('FIRE_PREVIEW')
const refleshNidaState = createAction('REFRESH_NIDA')
const keepItem = createAction('KEEP_SELECTED_ITEM')
const COVER_SELECTED = createAction('COVER_SELECTED')
const COMESA_COVER_SELECTED = createAction('COMESA_COVER_SELECTED')
const updatedCovers = createAction('UPDATED_COVERS')
const SELECTED_MOTO_TYPE = createAction('SELECTED_MOTO_TYPE')
export {
  COMESA_COVER_SELECTED,
  COVER_SELECTED,
  getCell,
  getDistrict,
  getNidaInformation,
  getProvince,
  getSector,
  getVillages,
  keepItem,
  refleshNidaState,
  restSubmitionStatus,
  SELECTED_MOTO_TYPE,
  sendQuotationToEmail,
  showHideFirePreview,
  submitFireQuotation,
  submitMotoQuotationAction,
  submitNoMotoQuotation,
  submitPersonalAccidentQuotation,
  updatedCovers,
}

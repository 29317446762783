import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import bkAppReducer from './root-reducer'
import { persistStore } from 'redux-persist'

const isDevelopment = process.env.REACT_APP_ENV === 'development'
console.log(process.env.REACT_APP_ENV)
const middleware = (getDefaultMiddleware) => {
  const middlewares = getDefaultMiddleware({
    serializableCheck: false,
  })

  if (isDevelopment) {
    middlewares.push(logger)
  }
  // middlewares.push(logger)
  return middlewares
}

const store = configureStore({
  reducer: {
    app: bkAppReducer,
  },
  middleware,
})

const persistor = persistStore(store)
export { store, persistor }
